<template>
  <head>
    <title>المفضلة</title>
  </head>
  <div class="favourite-view">
    <div class="container p-5">
      <div class="bg-whitesmoke basket-container p-5">
        <h1 class="w-100" v-if="data.length == 0">المفضلة فارغة</h1>
        <div class="flex-center">
          <div class="products-grid">
            <product-cards
              class="mx-4 my-4"
              v-for="(item, index) in data"
              :key="index"
              :src="item.src"
              :name="item.name"
              :price_discount="item.discount_price"
              :price="item.price"
              :discount="item.dis"
              :id="item.id"
              :favourite="1"
            ></product-cards>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCards from "@/components/Product/ProductCards.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "favourite-view",
  components: {
    ProductCards,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getData() {
      axios
        .get(store.state.server + "fav-index", {
          headers: { Authorization: `Bearer ${store.state.token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.length > 0)
            for (let i = 0; i < data.length; i++) {
              this.data.push(data[i].item);
            }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.basket-container {
  height: 80dvh;
  overflow-y: scroll;
}

.products-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 95%;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
  margin: 10px 0px 20px 0px;
}
</style>
