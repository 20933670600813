<template>
  <head>
    <title>تسجيل الدخول</title>
  </head>
  <div class="login-view">
    <div class="container p-5 flex-center">
      <div class="glassmorphism-card">
        <div class="my-5">
          <h1 style="font-weight: 800">اهلاً بعودتك</h1>
        </div>
        <div class="input w-100">
          <form v-on:submit.prevent="login()">
            <input
              type="text"
              class="my-inp"
              placeholder="ايميل"
              v-model="email"
            />
            <div class="password-container my-5">
              <input
                :type="inp_type"
                placeholder="كلمة المرور"
                class="my-inp"
                v-model="password"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="eye"
                @click="show_hide_password()"
              >
                <path fill="currentColor" :d="path" />
              </svg>
            </div>
            <button type="submit" class="login-btn mb-2">تسجيل الدخول</button>
          </form>
          <div class="mb-5 mt-3">
            <router-link to="/register" class="my-link"
              >ليس لديك حساب؟ اضغط هنا لأنشاء حساب جديد</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import router from "@/router";
export default {
  name: "login-view",
  data() {
    return {
      inp_type: "password",
      email: "",
      password: "",
      path: "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5",
    };
  },
  methods: {
    show_hide_password() {
      if (this.inp_type == "password") {
        this.inp_type = "text";
        this.path =
          "M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7";
      } else {
        this.inp_type = "password";
        this.path =
          "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5";
      }
    },
    login() {
      if (this.email == "") {
        alert("يجب عليك إدخال الإيميل");
        return;
      } else if (this.email.search(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/) < 0) {
        alert("هذا ليس إيميل");
        return;
      } else if (this.password == "") {
        alert("يجب عليك إدخال كلمة المرور");
        return;
      }
      axios(store.state.server + "login", {
        method: "POST",
        data: { email: this.email, password: this.password },
      })
        .then((response) => {
          store.state.token = response.data.token;
          store.state.role = response.data.role;
          window.sessionStorage.setItem("token", store.state.token);
          window.sessionStorage.setItem("role", store.state.role);
          if (store.state.role == "admin") {
            router.push("/admin-home");
          } else {
            router.push("/");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            alert("خطأ في الايميل او كلمة المرور");
          } else {
            alert("حدث خطأ يرجى المحاولة لاحقا");
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.glassmorphism-card {
  backdrop-filter: blur(5px) saturate(100%);
  -webkit-backdrop-filter: blur(5px) saturate(100%);
  background-color: rgba(189, 189, 189, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  height: fit-content;
  width: 50%;
  -webkit-box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 0 30px;
}

.my-inp {
  width: 100%;
  height: 50px;
  transition: 0.5s ease-in-out;
}

.eye {
  position: absolute;
  top: 14px;
  bottom: 0;
  left: 10px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.my-inp:focus {
  transform: translateY(-10px);
  -webkit-box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.75);
}

.password-container {
  position: relative;
}

.password-container > .my-inp:focus + .eye {
  transform: translateY(-10px);
}

.login-btn {
  background-color: #314152;
  color: whitesmoke;
  border: none;
  width: 100%;
}

.login-btn:hover {
  transform: translateY(-10px);
}

.my-link {
  text-decoration: none;
  color: #314152;
}
</style>
