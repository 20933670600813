<template>
  <div class="product-info">
    <head>
      <title>
        {{ data.name }}
      </title>
    </head>
    <div class="container py-5">
      <div class="bg-whitesmoke py-5">
        <div class="grid-2">
          <div class="photos-videos">
            <div class="abs-buttons">
              <div
                class="photos btn-style"
                :style="{ color: type == 'photo' ? '#c39a3e' : '' }"
                @click="type = 'photo'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M11.943 1.25h.114c2.309 0 4.118 0 5.53.19c1.444.194 2.584.6 3.479 1.494c.895.895 1.3 2.035 1.494 3.48c.19 1.411.19 3.22.19 5.529v.088c0 1.909 0 3.471-.104 4.743c-.104 1.28-.317 2.347-.795 3.235c-.21.391-.47.742-.785 1.057c-.895.895-2.035 1.3-3.48 1.494c-1.411.19-3.22.19-5.529.19h-.114c-2.309 0-4.118 0-5.53-.19c-1.444-.194-2.584-.6-3.479-1.494c-.793-.793-1.203-1.78-1.42-3.006c-.215-1.203-.254-2.7-.262-4.558c-.002-.473-.002-.973-.002-1.501v-.058c0-2.309 0-4.118.19-5.53c.194-1.444.6-2.584 1.494-3.479c.895-.895 2.035-1.3 3.48-1.494c1.411-.19 3.22-.19 5.529-.19m-5.33 1.676c-1.278.172-2.049.5-2.618 1.069c-.57.57-.897 1.34-1.069 2.619c-.174 1.3-.176 3.008-.176 5.386v.844l1.001-.876a2.3 2.3 0 0 1 3.141.104l4.29 4.29a2 2 0 0 0 2.564.222l.298-.21a3 3 0 0 1 3.732.225l2.83 2.547c.286-.598.455-1.384.545-2.493c.098-1.205.099-2.707.099-4.653c0-2.378-.002-4.086-.176-5.386c-.172-1.279-.5-2.05-1.069-2.62c-.57-.569-1.34-.896-2.619-1.068c-1.3-.174-3.008-.176-5.386-.176s-4.086.002-5.386.176"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                v-if="data['3d_image'] != null"
                class="3D btn-style"
                :style="{ color: type == '3d' ? '#c39a3e' : '' }"
                @click="type = '3d'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 100 100"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="m50 1.18l-.72 1.25l-6.973 12.062H47.5v42.98l-37.223 21.49l-2.597-4.498L0 87.783h1.441l13.934.008l-2.598-4.498L50 61.803l37.223 21.49l-2.598 4.498L100 87.783l-.72-1.25l-6.96-12.069l-2.597 4.499L52.5 57.473V14.491h5.193z"
                    color="currentColor"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M49.058 28.451L19.812 47.63c-.674.487-.855 1.041-.867 1.545v28.66c0 .63.328 1.216.867 1.545l29.246 19.178c.705.385 1.422.32 1.898-.01L80.188 79.38a1.81 1.81 0 0 0 .867-1.545v-28.66c-.006-.764-.33-1.167-.895-1.564l-29.218-19.16c-.696-.397-1.242-.208-1.884 0m.942 3.66l25.515 16.906l-25.531 17.206L24.23 49.174ZM22.56 52.394L48.192 69.37v24.425l-25.63-16.976Zm54.88 0v24.424L51.808 93.794V69.368Z"
                    color="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div class="myimgs-container">
              <div class="my-img-container">
                <div
                  clsas="img-bg-news"
                  :style="{
                    backgroundImage:
                      'URL(' +
                      $store.state.server_images +
                      encodeURI(src) +
                      ')',
                    height: '300px',
                    width: '500px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }"
                ></div>
                <div class="show-hide" @click="ShowHide()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="white"
                      d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                    />
                  </svg>
                </div>
              </div>
              <!-- img changer -->
              <div class="grid-3">
                <img
                  class="imgs"
                  :src="$store.state.server_images + encodeURI(data.src)"
                  @click="src = data.src"
                />
                <img
                  class="imgs"
                  v-for="(item, index) in data.images"
                  :key="index"
                  :src="$store.state.server_images + encodeURI(item.image)"
                  @click="src = item.image"
                />
              </div>
            </div>
          </div>
          <div class="content text-end">
            <div class="title-content">
              <div class="title">
                <div class="discount-title w-100">
                  <h4>
                    {{ data.name }} ({{
                      data.isAvailable == true ? "متاح" : "غير متاح حاليا"
                    }})&nbsp;
                  </h4>
                  <h6 class="discount" v-if="data.discount_price != null">
                    {{ data.dis.name }}
                  </h6>
                </div>
                <h6 class="under-title">رقم المنتج: {{ data.serial }}</h6>
              </div>
              <div class="price w-100">
                <div>
                  <h4 class="price-before" v-if="data.discount_price == null">
                    السعر: {{ data.price }}
                  </h4>
                  <h4 class="price-before" v-if="data.discount_price != null">
                    السعر بعد: {{ data.discount_price }}
                  </h4>
                  <h6 class="price-after" v-if="data.discount_price != null">
                    السعر قبل: {{ data.price }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="description my-5">
              <h4>وصف المنتج:</h4>
              <h5>{{ data.description }}</h5>
              <div class="table-responsive my-5">
                <table
                  class="table table-light table-striped table-hover text-center w-100"
                >
                  <thead>
                    <tr>
                      <th scope="col">الاسم</th>
                      <th scope="col">الوصف</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">الوزن</th>
                      <td>{{ data.weight }}KG</td>
                    </tr>
                    <tr>
                      <th scope="row">الطول</th>
                      <td>{{ data.height }}cm</td>
                    </tr>
                    <tr>
                      <th scope="row">العرض</th>
                      <td>{{ data.width }}cm</td>
                    </tr>
                    <tr>
                      <th scope="row">الأبعاد</th>
                      <td dir="ltr">{{ data.dimension }}cm<sup>3</sup></td>
                    </tr>
                    <tr>
                      <th scope="row">المواد</th>
                      <td>
                        <span
                          v-for="(item, index) in data.materials"
                          :key="index"
                          >{{ item.mat.name
                          }}<span
                            v-if="
                              index != Object.keys(data.materials).length - 1
                            "
                            >,&nbsp;</span
                          ></span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">الألوان</th>
                      <td>
                        <div class="row row-cols-auto w-100">
                          <span
                            v-for="(item, index) in data.colors"
                            :key="index"
                            class="col-3"
                          >
                            <div
                              class="color_circle"
                              :style="{ background: item.col.name }"
                            ></div>
                            <span
                              v-if="
                                index != Object.keys(data.colors).length - 1
                              "
                              >,&nbsp;</span
                            ></span
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="buy" v-if="data.isAvailable">
              <h4>شراء</h4>
              <input type="number" class="input" min="1" v-model="quantity" />
              <button class="add-cart my-4" @click="AddToBasket">
                إضافة إلى السلة
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 1024 1024"
                  class="mx-2"
                >
                  <path
                    fill="currentColor"
                    d="M1015.66 284a31.82 31.82 0 0 0-25.998-13.502H310.526l-51.408-177.28c-20.16-69.808-68.065-77.344-87.713-77.344H34.333c-17.569 0-31.777 14.224-31.777 31.776S16.78 79.425 34.332 79.425h137.056c4.336 0 17.568 0 26.593 31.184l176.848 649.936c3.84 13.712 16.336 23.183 30.591 23.183h431.968c13.409 0 25.376-8.4 29.905-21.024l152.256-449.68c3.504-9.744 2.048-20.592-3.888-29.024zM815.026 720.194H429.539L328.387 334.066h616.096zM752.003 848.13c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80m-288 0c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup" id="popup" style="opacity: 0; display: none">
      <span class="close" @click="ShowHide()">X</span>
      <div class="popup-content">
        <img :src="$store.state.server_images + encodeURI(src)" alt="" />
      </div>
    </div>
    <div class="popup" id="popup2" v-show="type == '3d'">
      <div class="popup-header">
        <div class="d-flex justify-content-end align-items-center">
          <span class="close" @click="type = 'photo'">X</span>
        </div>
      </div>
      <div class="popup-content">
        <canvas ref="mymodel"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import axios from "axios";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { computed } from "vue";
import { watch } from "vue";

export default {
  name: "product-info",
  data() {
    return {
      type: "photo",
      src: null,
      data: {},
      quantity: 1,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ShowHide() {
      const element = document.getElementById("popup");
      if (element.style.opacity == 0 || element.style.opacity === "") {
        element.style.opacity = 1;
        element.style.display = "block";
      } else {
        element.style.opacity = 0;
        element.style.display = "none";
      }
    },
    FindInBasket() {
      for (var key in store.state.basket) {
        if (store.state.basket[key].id == this.data.id) {
          return false;
        }
      }
      return true;
    },
    AddToBasket() {
      if (this.quantity >= 1) {
        if (this.FindInBasket()) {
          store.state.basket.push({
            id: this.data.id,
            name: this.data.name,
            price: this.data.price,
            price_dis: this.data.discount_price,
            item_total_price:
              this.data.discount_price != null
                ? this.data.discount_price * this.quantity
                : this.data.price * this.quantity,
            src: this.data.src,
            quantity: this.quantity,
          });
          store.state.index++;
          let balance =
            this.data.discount_price != null
              ? this.data.discount_price * this.quantity
              : this.data.price * this.quantity;
          store.state.balance += balance;
          alert("تم الإضافة إلى السلة بنجاح");
          window.localStorage.setItem(
            "basket",
            JSON.stringify(store.state.basket)
          );
          window.localStorage.setItem(
            "index",
            JSON.stringify(store.state.index)
          );
          window.localStorage.setItem(
            "balance",
            JSON.stringify(store.state.balance)
          );
          router.push("/basket");
        } else {
          alert("هذا المنتج موجود ضمن السلة");
        }
      } else {
        alert("عليك إختيارالكمية اولا");
      }
    },
    init() {
      const mycanvas = this.$refs.mymodel;
      const scene = new THREE.Scene();

      // Computed properties for width, height, and aspect ratio
      const width = computed(() => {
        return window.innerWidth / 2;
      });
      const height = computed(() => {
        return window.innerHeight;
      });
      const aspectRatio = computed(() => {
        return width.value / height.value;
      });

      // Update renderer and camera on window resize
      const updateRenderer = () => {
        renderer.setSize(width.value, height.value);
        renderer.setPixelRatio(window.devicePixelRatio);
      };
      const updateCamera = () => {
        camera.aspect = aspectRatio.value;
        camera.updateProjectionMatrix();
      };

      // Watch for changes in aspect ratio
      watch(aspectRatio, updateRenderer);
      watch(aspectRatio, updateCamera);

      // Initialize camera
      const camera = new THREE.PerspectiveCamera(
        100,
        aspectRatio.value,
        1,
        100000
      );
      camera.position.set(10, 4, 0);
      scene.add(camera);

      // Lighting
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(5, 10, 5);
      directionalLight.castShadow = true;
      scene.add(directionalLight);

      const hemisphereLight = new THREE.HemisphereLight(
        0xaaaaaa,
        0x000000,
        0.5
      );
      scene.add(hemisphereLight);

      // Shadow settings
      directionalLight.shadow.mapSize.width = 1024;
      directionalLight.shadow.mapSize.height = 1024;
      directionalLight.shadow.bias = -0.001;

      // Scene background
      scene.background = new THREE.Color(0xeeeeee);

      // Ground
      const groundGeometry = new THREE.PlaneGeometry(100, 100);
      const groundMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });
      const ground = new THREE.Mesh(groundGeometry, groundMaterial);
      ground.rotation.x = -Math.PI / 2;
      scene.add(ground);

      // GLTFLoader to load .glb model
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(
        store.state.server_images + this.data["3d_image"],
        (gltf) => {
          const loadedObject = gltf.scene;
          loadedObject.position.set(0, 1, 0);
          loadedObject.scale.set(1, 1, 1);
          loadedObject.castShadow = true;

          // Traverse the object and set double-sided materials if needed
          loadedObject.traverse((child) => {
            if (child.isMesh) {
              child.material.side = THREE.DoubleSide;
            }
          });

          scene.add(loadedObject);
        }
      );

      // Renderer
      const renderer = new THREE.WebGLRenderer({
        canvas: mycanvas,
        antialias: true,
      });
      renderer.setSize(width.value, height.value);
      renderer.shadowMap.enabled = true;

      // OrbitControls for camera interaction
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      // Update renderer and camera size
      updateRenderer();
      updateCamera();

      // Animation loop
      const loop = () => {
        renderer.render(scene, camera);
        controls.update();
        requestAnimationFrame(loop);
      };
      loop();
    },
    getData() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      axios
        .get(store.state.server + "show-item/" + id)
        .then((response) => {
          this.data = response.data;
          this.src = this.data.src;
          // 3D
          if (this.data["3d_image"] != null) {
            this.init();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.grid-2 {
  display: grid;
  grid-template-columns: auto auto;
}

.photos-videos {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abs-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: whitesmoke;
  padding: 10px 30px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.btn-style {
  margin: 0px 10px 0 10px;
  cursor: pointer;
}

.my-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.grid-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
}

.imgs {
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 20px 0px 20px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.imgs:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.content {
  width: 500px;
}

.title-content {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.discount-title {
  display: flex;
  justify-content: start;
  align-items: center;
}

.discount {
  background-color: rgb(17, 17, 26);
  color: whitesmoke;
  padding: 5px 10px;
  border-radius: 10px;
  animation: infinite 2s myanimate;
}

@keyframes myanimate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.price {
  display: flex;
  justify-content: end;
  align-items: center;
}

h4 {
  color: #c39a3e;
  font-weight: 700;
}

.under-title {
  color: rgb(101, 101, 101);
}

.price-after {
  color: rgb(101, 101, 101);
  text-decoration: line-through;
}

.popup {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.181);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
}

.popup-content {
  margin-top: 30px;
  position: relative;
  width: 100dvw;
  height: 90dvh;
}

.popup-content > img {
  height: 100%;
  width: fit-content;
}

.show-hide {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(195, 194, 194, 0.5);
  padding: 10px;
  cursor: pointer;
}

.close {
  position: fixed;
  z-index: 101;
  top: 10px;
  left: 5px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px;
  text-align: center;
  background-color: gray;
  color: white;
  cursor: pointer;
  border-radius: 50px;
}

.input {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px 20px;
}

.input:focus {
  outline: none !important;
}

.add-cart {
  border: none;
  border-radius: 10px;
  background-color: #c39a3e;
  color: whitesmoke;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.add-cart:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 10px 10px 5px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);
}
</style>
