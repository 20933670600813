<template>
  <head>
    <title>مشاريعنا</title>
  </head>
  <div class="news-view">
    <div class="container p-2">
      <div class="bg-whitesmoke my-5">
        <div class="page-title pt-5">مشاريعنا</div>
        <div class="page-content my-4 pb-5">
          <NewsCard
            class="m-3"
            v-for="(item, index) in data"
            :key="index"
            :img="item.image"
            :id="item.id"
            :title="item.name"
            :text="item.text"
          ></NewsCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/NewsCard/NewsCards.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "news-view",
  components: {
    NewsCard,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    readmore() {
      for (var key in this.data) {
        this.data[key].text = this.data[key].text.slice(0, 50);
        this.data[key].text += "...";
      }
    },
    getData() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.data = response.data;
          this.readmore();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.page-title {
  padding: 10px;
  font-size: 40px;
  color: #c39a3e;
}

.page-content {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
}
</style>
