<template>
  <head>
    <title>من نحن؟</title>
  </head>
  <div class="portfilio-page">
    <img
      v-for="(item, index) in data"
      :key="index"
      :src="item.src"
      :alt="item.id"
      :id="item.id"
      class="portfilio-image"
    />
  </div>
</template>

<script>
export default {
  name: "portfilio-page",
  data() {
    return {
      data: {
        0: { src: require("@/assets/Portfilio/First.png"), id: "first" },
        1: { src: require("@/assets/Portfilio/1.jpg"), id: "who_we_are" },
        2: { src: require("@/assets/Portfilio/2.jpg"), id: "message" },
        3: { src: require("@/assets/Portfilio/3.jpg"), id: "letter" },
        4: { src: require("@/assets/Portfilio/4.jpg"), id: "strategy" },
        5: { src: require("@/assets/Portfilio/5.jpg"), id: "welcome" },
        6: { src: require("@/assets/Portfilio/6.jpg"), id: "team" },
        7: { src: require("@/assets/Portfilio/7.jpg"), id: "offices" },
        8: { src: require("@/assets/Portfilio/8.jpg"), id: "projects" },
        9: { src: require("@/assets/Portfilio/card1.jpg"), id: "card1" },
        10: { src: require("@/assets/Portfilio/card2.jpg"), id: "card2" },
      },
    };
  },
  mounted() {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      const element = document.getElementById(id);
      // Scroll to the element
      element.scrollIntoView({ behavior: "smooth" });
    }, 500);
  },
};
</script>

<style scoped>
.portfilio-image {
  height: 100%;
  width: 100dvw;
}
</style>
