<template>
  <head>
    <title>إضافة نوع</title>
  </head>
  <div class="add-sub-category">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-center"
      >
        <CardComponent title="إضافة نوع">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addCategory()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name"
                  placeholder="اسم النوع"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <select
                  @change="getID()"
                  id="category"
                  v-model="category_name"
                  class="my-inp"
                  style="border: 1px solid black"
                >
                  <option value="اختر صنف" disabled selected>اختر صنف</option>
                  <option
                    v-for="(item, index) in categories"
                    :key="index"
                    :value="item.name"
                    :id="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-sub-category",
  data() {
    return {
      category_name: "اختر صنف",
      category_id: "",
      categories: [],
      name: "",
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    addCategory() {
      if (this.category_id == "" && this.name == "") {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }
      axios(store.state.server + "sub-category-store", {
        method: "POST",
        data: { name: this.name, category_id: this.category_id },
      })
        .then((response) => {
          if (response.status == 200) {
            alert("تمت الإضافة بنجاح");
            router.push("/admin-sub-categories");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData() {
      axios
        .get(store.state.server + "category-index")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getID() {
      const element = document.getElementById("category");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.category_name) {
          this.category_id = element[i].id;
          break;
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
