<template>
  <head>
    <title>تأكيد الطلب</title>
  </head>
  <div class="checkout-view">
    <div class="d-flex justify-content-center align-items-center py-5">
      <div class="checkout-container bg-whitesmoke p-5 mx-5">
        <h1 v-if="Object.keys($store.state.basket).length == 0">السلة فارغة</h1>
        <div
          class="d-flex justify-content-center align-items-start"
          v-if="Object.keys($store.state.basket).length > 0"
        >
          <div class="mx-5">
            <h3 class="text-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M1015.66 284a31.82 31.82 0 0 0-25.998-13.502H310.526l-51.408-177.28c-20.16-69.808-68.065-77.344-87.713-77.344H34.333c-17.569 0-31.777 14.224-31.777 31.776S16.78 79.425 34.332 79.425h137.056c4.336 0 17.568 0 26.593 31.184l176.848 649.936c3.84 13.712 16.336 23.183 30.591 23.183h431.968c13.409 0 25.376-8.4 29.905-21.024l152.256-449.68c3.504-9.744 2.048-20.592-3.888-29.024zM815.026 720.194H429.539L328.387 334.066h616.096zM752.003 848.13c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80m-288 0c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80"
                />
              </svg>
              راجع طلباتك
            </h3>
            <div class="table-responsive my-5">
              <table
                class="table table-light table-striped table-hover text-center w-100"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">الاسم</th>
                    <th scope="col">السعر</th>
                    <th scope="col">الكمية</th>
                    <th scope="col">السعر الكلي</th>
                    <th scope="col">إزالة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in $store.state.basket" :key="index">
                    <th scope="row">{{ parseInt(index + 1) }}</th>
                    <td>{{ item.name }}</td>
                    <td v-if="item.price_dis == null">
                      {{ item.price }}&nbsp;ر.س
                    </td>
                    <td v-else>{{ item.price_dis }}&nbsp;ر.س</td>
                    <td>
                      <input
                        type="number"
                        min="1"
                        class="my-inp h-100"
                        v-model="item.quantity"
                        @input="
                          OldQuantity = item.quantity;
                          UpdateQuantity(index);
                        "
                      />
                    </td>
                    <td v-if="item.price_dis == null">
                      {{ item.price * item.quantity }}&nbsp;ر.س
                    </td>
                    <td v-else>
                      {{ item.price_dis * item.quantity }}&nbsp;ر.س
                    </td>
                    <td
                      class="d-flex justify-content-center align-items-center"
                    >
                      <button class="remove" @click="RemoveFromBasket(index)">
                        إزالة
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 class="text-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 256a112 112 0 1 0-112-112a112 112 0 0 0 112 112m0 32c-69.42 0-208 42.88-208 128v64h416v-64c0-85.12-138.58-128-208-128"
                />
              </svg>
              عنوان الشحن
            </h3>
            <div class="card w-100 p-5 text-end my-5 grid-2">
              <div class="card-title grid-item">
                <h5>عنوان البريد الالكتروني:</h5>
                <input class="my-inp" type="email" v-model="email" />
              </div>
              <div class="card-title grid-item">
                <h5>الاسم الأول:</h5>
                <input class="my-inp" type="text" v-model="fname" />
              </div>
              <div class="card-title grid-item">
                <h5>الكنية:</h5>
                <input class="my-inp" type="text" v-model="lname" />
              </div>
              <div class="card-title grid-item">
                <h5>عنوان الشارع:</h5>
                <input class="my-inp" type="text" v-model="street" />
              </div>
              <div class="card-title grid-item">
                <h5>دولة:</h5>
                <div class="dropdown">
                  <button
                    class="my-inp dropdown-toggle w-100"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="background-color: unset"
                  >
                    {{ country }}
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item text-center"
                      style="cursor: pointer"
                      v-for="(item, index) in countries"
                      :key="index"
                      @click="country = item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-title grid-item">
                <h5>مدينة:</h5>
                <div class="dropdown">
                  <button
                    class="my-inp dropdown-toggle w-100"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="background-color: unset"
                  >
                    {{ city }}
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item">
                      <input
                        type="text"
                        placeholder="ابحث ضمن المدن"
                        class="my-inp w-100"
                        v-model="city_search"
                        @input="SearchCity"
                      />
                    </li>
                    <li
                      class="dropdown-item text-center"
                      style="cursor: pointer"
                      v-for="(item, index) in shown_citys"
                      :key="index"
                      @click="city = item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-title grid-item">
                <h5>رقم الهاتف:</h5>
                <input class="my-inp" type="text" v-model="phone" />
              </div>
            </div>
            <h3 class="text-end" v-if="later">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M22 10v6a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-6zM7.01 14H7a1 1 0 1 0 .01 2a1 1 0 0 0 0-2M13 14h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2m5-10a4 4 0 0 1 4 4H2a4 4 0 0 1 4-4z"
                />
              </svg>
              طريقة الدفع
            </h3>
            <div class="card w-100 p-5 text-end my-5 grid-2" v-if="later">
              <div
                class="grid-item"
                v-for="(item, index) in payment_methods"
                :key="index"
              >
                <input
                  type="radio"
                  name="payment"
                  :value="item.id"
                  :checked="item.id == payment_method"
                />
                <img :src="item.src" :alt="item.name" class="payment-photo" />
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="mx-5 sticky-top" style="z-index: 1">
            <h3
              class="text-end"
              v-if="Object.keys($store.state.basket).length > 0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  color="currentColor"
                >
                  <path
                    d="M20.016 2C18.903 2 18 4.686 18 8h2.016c.972 0 1.457 0 1.758-.335c.3-.336.248-.778.144-1.661C21.64 3.67 20.894 2 20.016 2"
                  />
                  <path
                    d="M18 8.054v10.592c0 1.511 0 2.267-.462 2.565c-.755.486-1.922-.534-2.509-.904c-.485-.306-.727-.458-.996-.467c-.291-.01-.538.137-1.062.467l-1.911 1.205c-.516.325-.773.488-1.06.488s-.545-.163-1.06-.488l-1.91-1.205c-.486-.306-.728-.458-.997-.467c-.291-.01-.538.137-1.062.467c-.587.37-1.754 1.39-2.51.904C2 20.913 2 20.158 2 18.646V8.054c0-2.854 0-4.28.879-5.167C3.757 2 5.172 2 8 2h12"
                  />
                  <path
                    d="M10 8c-1.105 0-2 .672-2 1.5s.895 1.5 2 1.5s2 .672 2 1.5s-.895 1.5-2 1.5m0-6c.87 0 1.612.417 1.886 1M10 8V7m0 7c-.87 0-1.612-.417-1.886-1M10 14v1"
                  />
                </g>
              </svg>
              قيمة الفاتورة
            </h3>
            <div class="card p-5 text-end my-5" style="width: 500px">
              <div class="card-title d-flex">
                <h4>السعر بدون الضريبة:&nbsp;</h4>
                <h4>{{ $store.state.balance }}&nbsp;ر.س</h4>
              </div>
              <div class="card-title d-flex">
                <h4>قيمة الضريبة:&nbsp;</h4>
                <h4>{{ $store.state.tax }}&nbsp;ر.س</h4>
              </div>
              <div class="card-title d-flex">
                <h4>السعر بعد الضريبة:&nbsp;</h4>
                <h4>{{ $store.state.total_balance }}&nbsp;ر.س</h4>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="checkout"
          v-if="Object.keys($store.state.basket).length > 0"
          @click="addOrder()"
        >
          إتمام طلب الشراء
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "checkout-view",
  data() {
    return {
      email: "",
      fname: "",
      lname: "",
      street: "",
      country: "",
      countries: {
        0: "المملكة العربية السعودية",
      },
      city: "",
      shown_citys: [],
      citys: [
        "ابها",
        "الباحة",
        "الاحساء",
        "النماص",
        "النعيرية",
        "الراس",
        "عرعر",
        "البدائع",
        "بدر",
        "بلجرشي",
        "بيشه",
        "البكيرية",
        "بريده",
        "الدمام",
        "الدوادمي",
        "حفر الباطن",
        "حائل",
        "الحرجة",
        "هويه",
        "حوطة بن تميم",
        "الحناكية",
        "هفوف",
        "حريمل",
        "حوطة سدير",
        "جزيرة فرسان",
        "جده",
        "جيزان",
        "الجوف",
        "الجبيل",
        "الخفجي",
        "خميس مشيط",
        "الخرج",
        "الخبر",
        "ليث",
        "المدينة المنورة",
        "محايل عسير",
        "مكه",
        "مستورة",
        "المخواة",
        "مارت",
        "المزاحمية",
        "نجران",
        "عنيزة",
        "العلا",
        "العينية",
        "القصيم",
        "القطيف",
        "القنفذه",
        "القريات",
        "القويعية",
        "رابغ",
        "رفحاء",
        "رنية",
        "رأس التنورة",
        "رماح",
        "الرياض",
        "رياض الخبراء",
        "روضة سدير",
        "الروضة",
        "سبت العلاية",
        "صفوى",
        "ساجر",
        "سكاكا",
        "صلبوخ",
        "سيهات",
        "شقراء",
        "شرورة",
        "الشراعية",
        "السليل",
        "تبوك",
        "الطائف",
        "تاروت",
        "ثادق",
        "طريف",
        "التربة",
        "املج",
        "وادي الدواسر",
        "ينبع",
        "ينبع الصناعية",
        "الزلفي",
        "الزلفي",
      ],
      city_search: "",
      phone: "",
      payment_methods: [
        {
          id: 1,
          name: "بايبال",
          src: require("@/assets/TestImages/PaymenMethods/PayPal.jpg"),
        },
        {
          id: 2,
          name: "ابل",
          src: require("@/assets/TestImages/PaymenMethods/apple.jpg"),
        },
        {
          id: 3,
          name: "ماستر كارد",
          src: require("@/assets/TestImages/PaymenMethods/Mastercard.webp"),
        },
      ],
      payment_method: 0,
      later: false,
    };
  },
  methods: {
    RemoveFromBasket(index) {
      store.commit("RemoveFromBasket", index);
      this.UpdateTotalBalance();
    },
    UpdateTotalBalance() {
      store.state.tax = (store.state.balance * 15) / 100;
      store.state.total_balance = (store.state.balance * 115) / 100;
    },
    UpdateQuantity(index) {
      if (store.state.basket[index].quantity == 0) {
        store.state.basket[index].quantity = 1;
        alert("لا يمكنك جعل كمية المنتج 0");
      }
      store.state.balance -= store.state.basket[index].item_total_price;
      store.state.basket[index].item_total_price =
        store.state.basket[index].price_dis != null
          ? store.state.basket[index].price_dis *
            store.state.basket[index].quantity
          : store.state.basket[index].price *
            store.state.basket[index].quantity;
      store.state.balance += store.state.basket[index].item_total_price;
      window.localStorage.setItem("basket", JSON.stringify(store.state.basket));
      window.localStorage.setItem("index", JSON.stringify(store.state.index));
      window.localStorage.setItem(
        "balance",
        JSON.stringify(store.state.balance)
      );
      this.UpdateTotalBalance();
    },
    SearchCity() {
      this.shown_citys = [];
      if (this.city_search == "") {
        this.shown_citys = this.citys;
      } else {
        var text = "";
        for (var i = 0; i < this.citys.length; i++) {
          text = this.citys[i];
          if (!text.search(this.city_search)) {
            this.shown_citys.push(this.citys[i]);
          }
        }
      }
    },
    addOrder() {
      if (
        this.email == "" ||
        this.fname == "" ||
        this.lname == "" ||
        this.street == "" ||
        this.city == "" ||
        this.phone == ""
      ) {
        alert("الرجاء إدخال الحقول المطلوبة");
        return;
      } else if (this.email.search(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/) < 0) {
        alert("هذا ليس إيميل");
        return;
      } else if (this.phone.search(/^0[1-9][- 0-9]{3,12}$/) < 0) {
        alert("هذا ليس رقم هاتف");
        return;
      }

      const fd = new FormData();
      for (let i = 0; i < Object.keys(store.state.basket).length; i++) {
        fd.append(`item_ids[${i}]`, store.state.basket[i].id);
        fd.append(`quantities[${i}]`, store.state.basket[i].quantity);
      }
      fd.append("total_price", store.state.total_balance);
      fd.append("email", this.email);
      fd.append("fname", this.fname);
      fd.append("lname", this.lname);
      fd.append("street_address", this.street);
      fd.append("city_name", this.city);
      fd.append("phone", this.phone);
      fd.append("tax", store.state.tax);

      axios
        .post(store.state.server + "add-order", fd)
        .then((response) => {
          if (
            response.status == 200 ||
            response.status == 201 ||
            response.status == 202
          ) {
            alert("تم تسجيل طلبك بنجاح");
            store.state.basket = [];
            store.state.balance = 0;
            store.state.index = 0;
            window.localStorage.removeItem("basket");
            window.localStorage.removeItem("balance");
            window.localStorage.removeItem("index");
            router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.UpdateTotalBalance();
    this.country = this.countries[0];
    this.city = this.citys[0];
    this.SearchCity();
  },
};
</script>

<style scoped>
.checkout-container {
  height: 80dvh;
  overflow-y: scroll;
  width: 100%;
}

.remove {
  text-decoration: none;
  background-color: unset;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(195, 62, 62, 0.5);
  color: #353535;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.remove:hover {
  transform: translateY(-10px);
  background-color: #c33e3e;
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.grid-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
}

.grid-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: start;
}

.grid-item {
  margin: 20px;
  font-size: 20px;
}

.my-inp {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

table > tbody > tr > td {
  vertical-align: baseline;
}

table > tbody > tr > th {
  vertical-align: baseline;
}

.dropdown-menu {
  height: 150px;
  overflow-y: scroll;
}

.payment-photo {
  height: 40px;
  width: fit-content;
  margin: 0 20px;
}

.checkout {
  text-decoration: none;
  background-color: unset;
  padding: 15px 0;
  font-size: 30px;
  border: 1px solid rgba(195, 155, 62, 0.8);
  color: #353535;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.checkout:hover {
  transform: translateY(-10px);
  background-color: rgba(195, 155, 62, 1);
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
