<template>
  <head>
    <title>التحقق من السلة</title>
  </head>
  <div class="basket-view">
    <div class="container p-5">
      <div class="bg-whitesmoke basket-container p-5">
        <h1 v-if="Object.keys($store.state.basket).length == 0">السلة فارغة</h1>
        <button class="remove w-100 mt-2 mb-5" @click="ClearBasket" v-else>
          <h4>حذف السلة</h4>
        </button>
        <div
          class="BasketProduct-card my-5"
          v-for="(item, index) in $store.state.basket"
          :key="index"
        >
          <div
            class="img-container"
            :style="{
              backgroundImage:
                'URL(' + $store.state.server_images + item.src + ')',
            }"
          ></div>
          <div class="my-card-body">
            <router-link
              :to="'/product-info?id=' + item.id"
              class="news-title text-end d-flex"
              style="text-decoration: none"
            >
              {{ parseInt(index + 1) + "- " + item.name }}
            </router-link>
            <div class="news-body text-end" v-if="item.price_dis == null">
              السعر: {{ item.price }}
            </div>
            <div class="news-body text-end" v-else>
              <div>السعر بعد الحسم: {{ item.price_dis }}</div>
              <div style="text-decoration: line-through">
                السعر قبل الحسم: {{ item.price }}
              </div>
            </div>
            <div class="news-body text-end">الكمية: {{ item.quantity }}</div>
          </div>
          <div class="card-footer">
            <button class="remove" @click="RemoveFromBasket(index)">
              إزالة
            </button>
          </div>
        </div>
        <router-link
          class="checkout"
          to="/checkout"
          v-if="Object.keys($store.state.basket).length > 0"
          >تأكيد عملية الشراء</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "basket-view",
  methods: {
    RemoveFromBasket(index) {
      store.commit("RemoveFromBasket", index);
    },
    ClearBasket() {
      store.state.basket = [];
      store.state.index = 0;
      store.state.balance = 0;
      window.localStorage.removeItem("basket");
      window.localStorage.removeItem("index");
      window.localStorage.removeItem("balance");
    },
  },
};
</script>

<style scoped>
.basket-container {
  height: 80dvh;
  overflow-y: scroll;
}

.BasketProduct-card {
  position: relative;
  background-color: white;
  padding: 10px;
  border: 2px solid rgba(195, 155, 62, 0.8);
  width: 100%;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px 0 50px 0;
}

.BasketProduct-card:hover {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transform: translateY(-20px);
}

.img-container {
  height: 175px;
  width: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba(100, 100, 100, 0.3);
  border-radius: 50px 0 50px 0;
}

.my-card-body {
  width: 100%;
  margin-right: 50px;
}

.news-title {
  color: #c39a3e;
  font-size: 30px;
}

.news-body {
  font-size: 18px;
}

.card-footer {
  width: 60%;
  margin-right: 20px;
}

.remove {
  text-decoration: none;
  background-color: unset;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(195, 62, 62, 0.5);
  color: #353535;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.remove:hover {
  transform: translateY(-10px);
  background-color: #c33e3e;
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.checkout {
  text-decoration: none;
  background-color: unset;
  padding: 15px 0;
  font-size: 30px;
  border: 1px solid rgba(195, 155, 62, 0.8);
  color: #353535;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.checkout:hover {
  transform: translateY(-10px);
  background-color: rgba(195, 155, 62, 1);
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
