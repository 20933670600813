<template>
  <head>
    <title>تعديل القسم</title>
  </head>
  <div class="edit-section">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-center"
      >
        <CardComponent title="تعديل قسم">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="EditSection()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name"
                  placeholder="اسم القسم"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="serial"
                  placeholder="سيريال القسم"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">تعديل</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
export default {
  name: "edit-section",
  data() {
    return {
      id: "",
      name: "",
      serial: "",
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    getData() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      const name = urlParams.get("name");
      this.id = id;
      this.name = name;
    },
    EditSection() {
      if (this.name == "" || this.serial == "") {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }
      axios(store.state.server + "section-update/" + this.id, {
        method: "POST",
        data: { name: this.name, serial: this.serial },
      })
        .then((response) => {
          if (response.status == 200) {
            alert("تم التعديل بنجاح");
            router.push("/admin-sections");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
