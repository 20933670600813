<template>
  <head>
    <title>إحصائيات</title>
  </head>
  <div class="admin-home">
    <SideBar>
      <div class="container bg-light h-100">
        <div class="display-grid mt-5 mb-3">
          <div class="card" v-for="(item, index) in data" :key="index">
            <div class="card-title">
              {{
                index == "users"
                  ? "المستخدمين"
                  : index == "items"
                  ? "المنتجات"
                  : index == "materials"
                  ? "المواد"
                  : index == "colors"
                  ? "الألوان"
                  : index == "sub_categories"
                  ? "الأنواع"
                  : index == "categories"
                  ? "الأصناف"
                  : index == "sections"
                  ? "الأقسام"
                  : "الطلبات"
              }}
            </div>
            <div class="card-body">{{ item }}</div>
          </div>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import axios from "axios";
import store from "@/store";
export default {
  name: "admin-home",
  data() {
    return {
      data: [],
    };
  },
  components: { SideBar },
  methods: {
    getData() {
      axios
        .get(store.state.server + "counting")
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.add-btn {
  height: 50px;
  width: fit-content;
  padding: 10px 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  background-color: #c39a3e;
  color: whitesmoke;
  transition: 0.5s ease-in-out;
}

.add-btn:hover {
  background-color: #ab8731;
}

.my-card {
  background-color: rgba(49, 65, 82, 0.9);
  border-radius: 10px;
  width: 500px;
  color: whitesmoke;
  padding: 40px 0;
}

.color_circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid black;
}

.display-grid {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: start;
}

.overflow-scroll {
  overflow-y: scroll;
}
</style>

<style scoped>
.card {
  padding: 10px;
  transition: 0.5s ease-in-out;
}

.card-title {
  font-size: 24px;
  font-weight: 700;
}

.card-body {
  font-size: 18px;
  font-weight: 500;
}

.card:hover {
  color: #c39a3e;
}
</style>
