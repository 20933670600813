<template>
  <head>
    <title>منتجاتنا</title>
  </head>
  <div class="products-view">
    <div class="page-body">
      <div class="sidebar">
        <div>
          <div class="sidebar-title" style="text-decoration: underline">
            اللون
          </div>
          <div class="row row-cols-auto">
            <div
              class="sidebar-content sidebar-cat col-3"
              v-for="(item, index) in colors"
              :key="index"
            >
              <div
                class="color-display"
                style="cursor: pointer"
                @click="
                  picked_color = item.id;
                  filter_shown_data();
                "
              >
                <div class="color">
                  <div
                    class="shown-color"
                    :style="{ backgroundColor: item.name }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="sidebar-title" style="text-decoration: underline">
            المواد
          </div>
          <div
            class="sidebar-content sidebar-cat w-100"
            v-for="(item, index) in materials"
            :key="index"
          >
            <div class="color-display">
              <div
                class="color-name"
                style="cursor: pointer"
                @click="
                  picked_material = item.id;
                  filter_shown_data();
                "
                :style="{
                  color: picked_material == item.id ? '#a78331' : '',
                }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="sidebar-title mt-4"
          style="text-decoration: underline; margin-bottom: 0; color: #314152"
        >
          الأقسام
        </div>
        <div v-for="(item, index) in filter_items" :key="index">
          <div class="sidebar-title sidebar-content">
            <router-link
              class="sidebar-title"
              to="products"
              :style="{
                color: $store.state.section == item.id ? '#a78331' : '',
              }"
              style="pointer-events: none"
            >
              {{ item.name }}
            </router-link>
          </div>

          <!-- Filter categories that belong to the current section (item.id) -->
          <div
            v-for="(item2, index2) in categories.filter(
              (cat) => cat.section_id == item.id
            )"
            :key="index2"
          >
            <div class="sidebar-content sidebar-cat">
              <router-link
                class="sidebar-cat"
                to="products"
                :style="{
                  color: $store.state.category == item2.id ? '#c39a3e' : '',
                }"
                style="pointer-events: none"
              >
                {{ item2.name }}
              </router-link>
            </div>

            <!-- Filter subcategories that belong to the current category (item2.id) -->
            <div
              v-for="(item3, index3) in sub_categories.filter(
                (subCat) => subCat.category_id == item2.id
              )"
              :key="index3"
            >
              <div class="sidebar-content sidebar-sub-cat">
                <router-link
                  class="sidebar-sub-cat"
                  :to="'/products?id=' + item3.id"
                  :style="{
                    color: $store.state.id == item3.id ? '#c39a3e' : '',
                  }"
                  @click="
                    getAttr();
                    filter_shown_data();
                  "
                >
                  {{ item3.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="searchbar">
          <div class="search-itme item1">
            <div class="item-title">السعر</div>
            <div class="labels">
              {{ min_price_selected }}ر.س - {{ max_price_selected }}ر.س
            </div>
            <div class="rangeslider">
              <input
                class="min input-ranges"
                name="range_1"
                type="range"
                :min="min_price"
                :max="max_price / 2"
                v-model="min_price_selected"
                @change="filter_shown_data"
              />
              <input
                class="max input-ranges"
                name="range_1"
                type="range"
                :min="max_price / 2 + min_price"
                :max="max_price"
                v-model="max_price_selected"
                @change="filter_shown_data"
              />
            </div>
          </div>
          <div class="search-item item2">
            <div class="item-title">خصومات</div>
            <div class="radio-row">
              <span
                v-for="(item, index) in discounts"
                :key="index"
                class="mx-2"
              >
                <input
                  type="radio"
                  name="discount"
                  v-model="picked_discount"
                  :value="item.id"
                  class="my-radio"
                  @click="filter_shown_data"
                  :checked="picked_discount == item.id"
                />
                <span class="mx-2">{{ item.name }}</span>
              </span>
            </div>
          </div>
          <div class="search-item item3 mt-5 mb-3">
            <button @click="clear_filter()" class="clear-filter">
              حذف الفلتر
            </button>
          </div>
        </div>
        <div class="products">
          <div class="flex-center">
            <div class="products-grid">
              <product-cards
                class="mx-4 my-4"
                v-for="(item, index) in shown_data"
                :key="index"
                :src="item.src"
                :name="item.name"
                :price_discount="item.discount_price"
                :price="item.price"
                :discount="item.dis"
                :id="item.id"
                :favourite="item.favourite"
              ></product-cards>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import ProductCards from "@/components/Product/ProductCards.vue";
import axios from "axios";

export default {
  name: "products-view",
  data() {
    return {
      filter_items: [],
      categories: [],
      sub_categories: [],
      colors: [],
      materials: [],
      discounts: [],
      data: {},
      shown_data: {},
      picked_color: 0,
      picked_material: 0,
      picked_discount: 0,
      min_price: 1,
      max_price: 0,
      min_price_selected: 1,
      max_price_selected: 0,
    };
  },
  methods: {
    getAttr() {
      setTimeout(() => {
        store.commit("getAttr");
        if (store.state.discount != 0) {
          this.picked_discount = store.state.discount;
        }
      }, 100);
    },
    filter_section_id(id) {
      var arr = {};
      for (var i = 0; i < Object.keys(this.data).length; i++) {
        if (this.data[i]["section_id"] == id) {
          arr[i] = this.data[i];
        }
      }
      return arr;
    },
    filter_category_id(id) {
      var arr = {};
      for (var i = 0; i < Object.keys(this.data).length; i++) {
        if (this.data[i]["category_id"] == id) {
          arr[i] = this.data[i];
        }
      }
      return arr;
    },
    filter_sub_category_id(id) {
      var arr = {};
      for (var i = 0; i < Object.keys(this.data).length; i++) {
        if (this.data[i]["sub_category_id"] == id) {
          arr[i] = this.data[i];
        }
      }
      return arr;
    },
    filter_color(colors, id) {
      for (var key in colors) {
        if (colors[key].color_id == id) {
          return true;
        }
      }
      return false;
    },
    filter_material(materials, id) {
      for (var key in materials) {
        if (materials[key].material_id == id) {
          return true;
        }
      }
      return false;
    },
    filter_shown_data() {
      setTimeout(() => {
        this.shown_data = {};
        var arr = {};
        var arr_2 = {};
        var arr_3 = {};
        const sub_category_id = store.state.sub_category;
        if (sub_category_id != 0) {
          arr = this.filter_sub_category_id(sub_category_id);
        } else {
          arr = this.data;
        }
        if (
          this.picked_color != 0 ||
          this.picked_discount != 0 ||
          this.picked_material != 0
        ) {
          for (var key in arr) {
            if (
              this.picked_color != 0 &&
              this.picked_discount != 0 &&
              this.picked_material != 0
            ) {
              if (
                this.filter_color(arr[key].colors, this.picked_color) &&
                this.filter_material(
                  arr[key].materials,
                  this.picked_material
                ) &&
                arr[key].discount_id == this.picked_discount
              ) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color == 0 &&
              this.picked_discount != 0 &&
              this.picked_material != 0
            ) {
              if (
                this.filter_material(
                  arr[key].materials,
                  this.picked_material
                ) &&
                arr[key].discount_id == this.picked_discount
              ) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color != 0 &&
              this.picked_discount != 0 &&
              this.picked_material == 0
            ) {
              if (
                this.filter_color(arr[key].colors, this.picked_color) &&
                arr[key].discount_id == this.picked_discount
              ) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color != 0 &&
              this.picked_discount == 0 &&
              this.picked_material != 0
            ) {
              if (
                this.filter_color(arr[key].colors, this.picked_color) &&
                this.filter_material(arr[key].materials, this.picked_material)
              ) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color == 0 &&
              this.picked_discount != 0 &&
              this.picked_material == 0
            ) {
              if (arr[key].discount_id == this.picked_discount) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color != 0 &&
              this.picked_discount == 0 &&
              this.picked_material == 0
            ) {
              if (this.filter_color(arr[key].colors, this.picked_color)) {
                arr_2[key] = arr[key];
              }
            } else if (
              this.picked_color == 0 &&
              this.picked_discount == 0 &&
              this.picked_material != 0
            ) {
              if (
                this.filter_material(arr[key].materials, this.picked_material)
              ) {
                arr_2[key] = arr[key];
              }
            }
          }
          arr_3 = arr_2;
        } else {
          arr_3 = arr;
        }
        for (key in arr_3) {
          if (arr_3[key].price_discount != null) {
            if (
              arr_3[key].price_discount <= this.max_price_selected &&
              arr_3[key].price_discount >= this.min_price_selected
            ) {
              this.shown_data[key] = arr_3[key];
            }
          } else {
            if (
              arr_3[key].price <= this.max_price_selected &&
              arr_3[key].price >= this.min_price_selected
            ) {
              this.shown_data[key] = arr_3[key];
            }
          }
        }
      }, 200);
    },
    clear_filter() {
      location.replace("/products");
    },
    getItems() {
      axios
        .get(store.state.server + "item-index")
        .then((response) => {
          this.data = response.data;
          const data = response.data;
          for (let i = 0; i < data.length; i++) {
            if (data[i].price > this.max_price) {
              this.max_price = data[i].price;
            }
          }
          this.max_price_selected = this.max_price;
          this.getAttr();
          this.filter_shown_data();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSections() {
      axios
        .get(store.state.server + "section-index")
        .then((response) => {
          this.filter_items = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategories() {
      axios
        .get(store.state.server + "category-index")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubCategories() {
      axios
        .get(store.state.server + "sub-category-index")
        .then((response) => {
          this.sub_categories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColors() {
      axios
        .get(store.state.server + "color-index")
        .then((response) => {
          this.colors = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMaterials() {
      axios
        .get(store.state.server + "material-index")
        .then((response) => {
          this.materials = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDiscounts() {
      axios
        .get(store.state.server + "index-dis")
        .then((response) => {
          this.discounts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    ProductCards,
  },
  mounted() {
    this.getSections();
    this.getCategories();
    this.getSubCategories();
    this.getColors();
    this.getMaterials();
    this.getDiscounts();
    this.getItems();
  },
};
</script>

<style scoped>
.page-body {
  width: 100%;
  text-align: start;
  display: grid;
  grid-template-columns: 15dvw 85dvw;
}

.sidebar {
  background-color: whitesmoke;
  padding: 30px 30px 30px 30px;
  height: 90dvh;
  overflow-y: scroll;
}

.sidebar-title {
  color: #c39a3e;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 5px;
  transition: 0.5s ease-in-out;
}

.sidebar-title:hover {
  color: #a78331;
}

.sidebar-content {
  width: fit-content;
  transition: 0.5s ease-in-out;
}

.sidebar-content:hover {
  transform: translateX(-10px);
}

.sidebar-cat {
  margin: 0 10px 5px 0;
  color: rgb(100, 100, 100);
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  transition: 0.5s ease-in-out;
}

.sidebar-cat:hover {
  color: #c39a3e;
}

.sidebar-sub-cat {
  margin: 0 20px 5px 0;
  font-size: 19px;
  color: rgb(100, 100, 100);
  text-decoration: none;
  transition: 0.5s ease-in-out;
}

.color-display {
  display: grid;
  grid-template-columns: auto auto;
}

.color {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.shown-color {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
}

.sidebar-content:hover > .color-display > .color > .shown-color {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.sidebar-sub-cat:hover {
  color: #c39a3e;
}

.show-hide {
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.show-hide:hover {
  color: #c39a3e;
}

.content {
  overflow-y: scroll;
  height: 90dvh;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
}

.item1 {
  grid-area: price;
}
.item2 {
  grid-area: discount;
}
.item3 {
  grid-area: delete;
}

.searchbar {
  background-color: rgba(245, 245, 245, 0.7);
  display: grid;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  grid-template-areas:
    "price price price discount discount discount"
    "delete delete delete delete delete delete";
  gap: 10px;
}

.item-title {
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;
  color: #c39a3e;
  margin-bottom: 10px;
}

.labels {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.radio-row {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
}

.radio-row > span {
  display: flex;
  justify-content: start;
  align-items: center;
}

.my-radio {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.products-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: rgba(245, 245, 245, 0.7);
  width: 95%;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
  margin: 10px 0px 20px 0px;
}

.clear-filter {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 20px;
  background-color: #c39a3e;
  border: none;
  border-radius: 10px;
  color: whitesmoke;
  text-align: center;
  transition: 0.5s ease-in-out;
}

.clear-filter:hover {
  background-color: #a78331;
}

/* range */
.input-ranges[type="range"] {
  width: 210px;
  height: 30px;
  overflow: hidden;
  outline: none;
}

.input-ranges[type="range"],
.input-ranges[type="range"]::-webkit-slider-runnable-track,
.input-ranges[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: none;
}

.input-ranges[type="range"]::-webkit-slider-runnable-track {
  width: 200px;
  height: 1px;
  background: #3f4656;
}

.input-ranges[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
  background: none;
}

.input-ranges[type="range"]::-webkit-slider-thumb {
  position: relative;
  height: 15px;
  width: 15px;
  margin-top: -7px;
  background: #c39a3e;
  border-radius: 25px;
  cursor: pointer;
  z-index: 1;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.input-ranges[type="range"]::-webkit-slider-thumb:hover {
  background: #a78331;
}

.input-ranges[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.input-ranges[type="range"]:nth-child(1)::-webkit-slider-thumb {
  z-index: 2;
}

.rangeslider {
  font-family: sans-serif;
  font-size: 14px;
  position: relative;
  height: 20px;
  width: 210px;
  display: inline-block;
  margin-top: -5px;
}

.rangeslider input {
  position: absolute;
}

.rangeslider span {
  position: absolute;
  margin-top: 20px;
  left: 0;
}

.rangeslider .right {
  position: relative;
  float: right;
  margin-right: -5px;
}
</style>
