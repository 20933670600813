<template>
  <head>
    <title>تعديل صنف</title>
  </head>
  <div class="edit-category">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-center"
      >
        <CardComponent title="تعديل صنف">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="editCategory()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name"
                  placeholder="اسم الصنف"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <select
                  @change="getID()"
                  id="section"
                  v-model="section_name"
                  class="my-inp"
                  style="border: 1px solid black"
                >
                  <option value="اختر قسم" disabled selected>اختر قسم</option>
                  <option
                    v-for="(item, index) in sections"
                    :key="index"
                    :value="item.name"
                    :id="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "edit-category",
  data() {
    return {
      section_name: "اختر قسم",
      section_id: "",
      sections: [],
      name: "",
      id: "",
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    editCategory() {
      if (this.section_id == "" && this.name == "") {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }
      axios(store.state.server + "category-update/" + this.id, {
        method: "POST",
        data: { name: this.name, section_id: this.section_id },
      })
        .then((response) => {
          if (response.status == 200) {
            alert("تم التعديل بنجاح");
            router.push("/admin-categories");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData() {
      axios
        .get(store.state.server + "section-index")
        .then((response) => {
          this.sections = response.data;
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const id = urlParams.get("id");
          const name = urlParams.get("name");
          const section_id = urlParams.get("section_id");
          this.id = id;
          this.name = name;
          this.section_id = section_id;
          for (let i = 0; i < this.sections.length; i++) {
            if (this.sections[i].id == this.section_id) {
              this.section_name = this.sections[i].name;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getID() {
      const element = document.getElementById("section");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.section_name) {
          this.section_id = element[i].id;
          break;
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
