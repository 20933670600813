<template>
  <head>
    <title>كلمة الرئيس التنفيذي والرؤية</title>
  </head>
  <div class="portfilio-page">
    <img
      v-for="(item, index) in data"
      :key="index"
      :src="item.src"
      :alt="item.id"
      :id="item.id"
      class="portfilio-image"
    />
  </div>
</template>

<script>
export default {
  name: "portfilio-page",
  data() {
    return {
      data: {
        0: { src: require("@/assets/Portfilio/2.jpg"), id: "message" },
        1: { src: require("@/assets/Portfilio/3.jpg"), id: "letter" },
      },
    };
  },
};
</script>

<style scoped>
.portfilio-image {
  height: 100%;
  width: 100dvw;
}
</style>
