<template>
  <div class="side-bar">
    <div class="full-content">
      <div class="right-side">
        <router-link to="/admin-home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              d="M13 9V3h8v6zM3 13V3h8v10zm10 8V11h8v10zM3 21v-6h8v6z"
            />
          </svg>
          الإحصائيات</router-link
        >
        <router-link to="/admin-orders">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M5.586 4.586C5 5.172 5 6.114 5 8v9c0 1.886 0 2.828.586 3.414S7.114 21 9 21h6c1.886 0 2.828 0 3.414-.586S19 18.886 19 17V8c0-1.886 0-2.828-.586-3.414S16.886 4 15 4H9c-1.886 0-2.828 0-3.414.586M9 8a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2zm0 4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2z"
              clip-rule="evenodd"
            />
          </svg>
          الطلبات</router-link
        >
        <router-link to="/admin-sections">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              d="M5 20v-9.15L2.2 13L1 11.4L12 3l4 3.05V4h3v4.35l4 3.05l-1.2 1.6l-2.8-2.15V20h-5v-6h-4v6zm5-9.975h4q0-.8-.6-1.313T12 8.2t-1.4.513t-.6 1.312"
            />
          </svg>
          الأقسام الرئيسة</router-link
        >
        <router-link to="/admin-categories">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              d="M3 21v-2h2V3h10v1h4v15h2v2h-4V6h-2v15zm8-8q.425 0 .713-.288T12 12t-.288-.712T11 11t-.712.288T10 12t.288.713T11 13"
            />
          </svg>
          الأصناف</router-link
        >
        <router-link to="/admin-sub-categories">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              d="M12.5 7c0-1.11.89-2 2-2H18c1.1 0 2 .9 2 2v2.16c-1.16.41-2 1.51-2 2.81V14h-5.5zM6 11.96V14h5.5V7c0-1.11-.89-2-2-2H6c-1.1 0-2 .9-2 2v2.15c1.16.41 2 1.52 2 2.81m14.66-1.93c-.98.16-1.66 1.09-1.66 2.09V15H5v-3a2 2 0 1 0-4 0v5c0 1.1.9 2 2 2v2h2v-2h14v2h2v-2c1.1 0 2-.9 2-2v-5c0-1.21-1.09-2.18-2.34-1.97"
            />
          </svg>
          الأنواع</router-link
        >
        <router-link to="/admin-colors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <path
              fill="currentColor"
              d="M17.5 12a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 17.5 9a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5m-3-4A1.5 1.5 0 0 1 13 6.5A1.5 1.5 0 0 1 14.5 5A1.5 1.5 0 0 1 16 6.5A1.5 1.5 0 0 1 14.5 8m-5 0A1.5 1.5 0 0 1 8 6.5A1.5 1.5 0 0 1 9.5 5A1.5 1.5 0 0 1 11 6.5A1.5 1.5 0 0 1 9.5 8m-3 4A1.5 1.5 0 0 1 5 10.5A1.5 1.5 0 0 1 6.5 9A1.5 1.5 0 0 1 8 10.5A1.5 1.5 0 0 1 6.5 12M12 3a9 9 0 0 0-9 9a9 9 0 0 0 9 9a1.5 1.5 0 0 0 1.5-1.5c0-.39-.15-.74-.39-1c-.23-.27-.38-.62-.38-1a1.5 1.5 0 0 1 1.5-1.5H16a5 5 0 0 0 5-5c0-4.42-4.03-8-9-8"
            />
          </svg>
          الألوان</router-link
        >
        <router-link to="/admin-materials">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 48 48"
            class="mx-2"
          >
            <defs>
              <mask id="ipSMaterial0">
                <g fill="none" stroke-linejoin="round" stroke-width="4">
                  <path
                    fill="#fff"
                    stroke="#fff"
                    d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"
                  />
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    d="M24 36c6.627 0 12-5.373 12-12"
                  />
                </g>
              </mask>
            </defs>
            <path
              fill="currentColor"
              d="M0 0h48v48H0z"
              mask="url(#ipSMaterial0)"
            />
          </svg>
          المواد</router-link
        >
        <router-link to="/admin-products">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 14 14"
            class="mx-2"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M8.294 1.125h2.364c.374 0 .676.27.676.602v5.984H2.646l-.097.001V1.727c0-.333.302-.602.676-.602H5.59v3.014c0 .08.036.156.1.213a.36.36 0 0 0 .238.088h2.028a.36.36 0 0 0 .239-.088a.3.3 0 0 0 .099-.213zM2.646 8.96h8.708a2.448 2.448 0 0 1 0 4.895H2.646a2.448 2.448 0 1 1 0-4.895m1.761 2.44a.875.875 0 1 1-1.75 0a.875.875 0 0 1 1.75 0m3.473 0a.875.875 0 1 1-1.75 0a.875.875 0 0 1 1.75 0m2.597.874a.875.875 0 1 0 0-1.75a.875.875 0 0 0 0 1.75"
              clip-rule="evenodd"
            />
          </svg>
          المنتجات</router-link
        >
        <router-link to="/admin-users">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M16 17v2H2v-2s0-4 7-4s7 4 7 4m-3.5-9.5A3.5 3.5 0 1 0 9 11a3.5 3.5 0 0 0 3.5-3.5m3.44 5.5A5.32 5.32 0 0 1 18 17v2h4v-2s0-3.63-6.06-4M15 4a3.4 3.4 0 0 0-1.93.59a5 5 0 0 1 0 5.82A3.4 3.4 0 0 0 15 11a3.5 3.5 0 0 0 0-7"
            />
          </svg>
          المستخدمين</router-link
        >
        <router-link to="/admin-news">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M3 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm3 4h6v6H6zm2 2v2h2V9zm10 0h-4V7h4zm-4 4v-2h4v2zm-8 4v-2h12v2z"
            />
          </svg>
          الأخبار</router-link
        >
        <router-link to="/admin-discounts">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M20.04 8.71V4h-4.7L12 .69L8.71 4H4v4.71L.69 12L4 15.34v4.7h4.71L12 23.35l3.34-3.31h4.7v-4.7L23.35 12zM8.83 7.05c.98 0 1.77.79 1.77 1.78a1.77 1.77 0 0 1-1.77 1.77c-.99 0-1.78-.79-1.78-1.77c0-.99.79-1.78 1.78-1.78M15.22 17c-.98 0-1.77-.8-1.77-1.78a1.77 1.77 0 0 1 1.77-1.77c.98 0 1.78.79 1.78 1.77A1.78 1.78 0 0 1 15.22 17m-6.72.03L7 15.53L15.53 7l1.5 1.5z"
            />
          </svg>
          الخصومات</router-link
        >
        <router-link to="/" @click="Logout()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            class="mx-2"
          >
            <g fill="none">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M10.138 1.815A3 3 0 0 1 14 4.688v14.624a3 3 0 0 1-3.862 2.873l-6-1.8A3 3 0 0 1 2 17.512V6.488a3 3 0 0 1 2.138-2.873zM15 4a1 1 0 0 1 1-1h3a3 3 0 0 1 3 3v1a1 1 0 1 1-2 0V6a1 1 0 0 0-1-1h-3a1 1 0 0 1-1-1m6 12a1 1 0 0 1 1 1v1a3 3 0 0 1-3 3h-3a1 1 0 1 1 0-2h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1M9 11a1 1 0 1 0 0 2h.001a1 1 0 1 0 0-2z"
                clip-rule="evenodd"
              />
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 12h5m0 0l-2-2m2 2l-2 2"
              />
            </g>
          </svg>
          تسجيل الخروج</router-link
        >
      </div>
      <div class="left-side">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
export default {
  name: "side-bar",
  methods: {
    Logout() {
      store.state.token = "";
      store.state.role = "";
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("role");
      router.push("/");
    },
  },
  mounted() {
    if (store.state.role != "admin") {
      router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
a {
  font-weight: bold;
  color: #314152;
  text-decoration: none;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  &.router-link-exact-active {
    text-decoration: none;
    background-color: #314152;
    color: whitesmoke;
  }
  transition: 0.5s ease-in-out;
}

a:hover {
  color: whitesmoke;
  background-color: #314152;
}

.full-content {
  height: 90dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-side {
  height: 100%;
  width: 25%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.left-side {
  height: 100%;
  width: 75%;
  overflow-y: scroll;
}
</style>
