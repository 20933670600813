<template>
  <div class="product-card">
    <div class="my-product-card">
      <img
        class="img-container"
        :src="$store.state.server_images + encodeURI(src)"
      />
      <div class="my-card-body">
        <div class="name-price">
          <div class="name">
            <h4 class="card-title price-after text-end">{{ name }}</h4>
            <h6
              class="card-text price price-before"
              v-if="price_discount != null"
            >
              السعر قبل: {{ price }} ر.س
            </h6>
          </div>
          <h5 class="price" v-if="price_discount == null">
            <span class="price-border"> {{ price }} ر.س </span>
          </h5>
          <h5 class="price" v-if="price_discount != null">
            <span class="price-border"> {{ price_discount }} ر.س </span>
          </h5>
        </div>
        <div class="discount" v-if="price_discount != null">
          {{ discount.name }}
        </div>
        <div
          class="favourite"
          v-if="favourite == null && $store.state.token != ''"
          @click="addToFavourite(id)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            style="cursor: pointer"
          >
            <path
              fill="#000000"
              d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3"
            />
          </svg>
        </div>
        <div
          class="favourite"
          v-if="favourite != null && $store.state.token != ''"
          @click="addToFavourite(id)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            style="cursor: pointer"
          >
            <path
              fill="#b81919"
              d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z"
            />
          </svg>
        </div>
        <div class="text-center">(باستثناء الضريبة على القيمة المضافة)</div>
      </div>
      <div class="card-footer mt-4 mb-2">
        <router-link :to="'/product-info?id=' + id" class="order-now">
          <div class="mx-1">اطلب الآن</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            class="mx-1"
            viewBox="0 0 1024 1024"
          >
            <path
              fill="currentColor"
              d="M1015.66 284a31.82 31.82 0 0 0-25.998-13.502H310.526l-51.408-177.28c-20.16-69.808-68.065-77.344-87.713-77.344H34.333c-17.569 0-31.777 14.224-31.777 31.776S16.78 79.425 34.332 79.425h137.056c4.336 0 17.568 0 26.593 31.184l176.848 649.936c3.84 13.712 16.336 23.183 30.591 23.183h431.968c13.409 0 25.376-8.4 29.905-21.024l152.256-449.68c3.504-9.744 2.048-20.592-3.888-29.024zM815.026 720.194H429.539L328.387 334.066h616.096zM752.003 848.13c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80m-288 0c-44.192 0-80 35.808-80 80s35.808 80 80 80s80-35.808 80-80s-35.808-80-80-80"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
  name: "product-cards",
  props: [
    "src",
    "name",
    "price_discount",
    "price",
    "discount",
    "id",
    "favourite",
  ],
  methods: {
    addToFavourite(id) {
      axios
        .get(store.state.server + "item-fav/" + id, {
          headers: { Authorization: `Bearer ${store.state.token}` },
        })
        .then((response) => {
          if (response.data == "deleted from fav") {
            alert("تمت الإزالة من المفضلة");
          } else {
            alert("تمت الإضافة إلى المفضلة");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.my-product-card {
  position: relative;
  background-color: white;
  padding: 10px;
  border: 2px solid rgba(195, 155, 62, 0.8);
  width: 18rem;
  transition: 0.5s ease-in-out;
}

.my-product-card:hover {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transform: translateY(-20px);
}

.img-container {
  height: 200px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba(100, 100, 100, 0.3);
}

.my-card-body {
  width: 100%;
}

.price {
  text-align: end;
}

.price-border {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.price-after {
  color: #c39a3e;
}

.price-before {
  color: rgb(176, 176, 176);
  text-decoration: line-through;
}

.discount {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #191919;
  color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
  animation: infinite 2.5s op_animate;
}

.name {
  display: grid;
  justify-content: start;
  align-items: center;
}

.name-price {
  display: grid;
  grid-template-columns: auto auto;
  height: 60px;
  align-items: center;
  width: 100%;
  margin: 15px 0 15px 0;
}

.order-now {
  text-decoration: none;
  background-color: unset;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(195, 155, 62, 0.5);
  color: #808080;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-now:hover {
  transform: translateY(-10px);
  background-color: #c39a3e;
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

@keyframes op_animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.favourite {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
