<template>
  <head>
    <title>إضافة لون</title>
  </head>
  <div class="add-color">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-center"
      >
        <CardComponent title="إضافة لون">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addColor()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="color"
                  v-model="name"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-color",
  data() {
    return {
      name: "",
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    addColor() {
      if (this.name == "") {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }
      axios(store.state.server + "add-color", {
        method: "POST",
        data: { name: this.name },
      })
        .then((response) => {
          if (response.status == 200) {
            alert("تمت الإضافة بنجاح");
            router.push("/admin-colors");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
