import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
// Products
import ProductsView from "@/views/Products/ProductsView.vue";
import ProductInfo from "@/views/Products/ProductInfo.vue";
// News
import NewsView from "@/views/News/NewsView.vue";
import NewsInfo from "@/views/News/NewsInfo.vue";
// Basket
import BasketView from "@/views/Basket/BasketView.vue";
import CheckoutView from "@/views/Basket/CheckoutView.vue";
// Favourite
import FavouriteView from "@/views/Favourite/FavouriteView.vue";
// Login
import LoginView from "@/views/LoginView.vue";
// Register
import RegisterView from "@/views/RegisterView.vue";
// Portfilio
import PortfilioPage from "@/views/Portfilio/PortfilioPage.vue";
// Letter and vision
import MessagePage from "@/views/Portfilio/MessagePage.vue";

// Admin Dashboard
import AdminHome from "@/views/AdminDashboard/AdminHome.vue";
// Admin Orders
import OrdersView from "@/views/AdminDashboard/Orders/OrdersView.vue";
// Admin sections
import SectionsView from "@/views/AdminDashboard/Sections/SectionsView.vue";
// Admin add section
import AddSection from "@/views/AdminDashboard/Sections/AddSection.vue";
// Admin edit section
import EditSection from "@/views/AdminDashboard/Sections/EditSection.vue";
// Admin categories
import CategoriesView from "@/views/AdminDashboard/Categories/CategoriesView.vue";
// Admin add category
import AddCategory from "@/views/AdminDashboard/Categories/AddCategory.vue";
// Admin Edit Category
import EditCategory from "@/views/AdminDashboard/Categories/EditCategory.vue";
// Admin sub-categories
import SubcategoriesView from "@/views/AdminDashboard/SubCategories/SubcategoriesView.vue";
// Admin add sub-categories
import AddSubcategory from "@/views/AdminDashboard/SubCategories/AddSubcategory.vue";
// Admin add sub-categories
import EditSubcategory from "@/views/AdminDashboard/SubCategories/EditSubcategory.vue";
// Admin colors
import ColorsView from "@/views/AdminDashboard/Colors/ColorsView.vue";
// Admin add colors
import AddColor from "@/views/AdminDashboard/Colors/AddColor.vue";
// Admin materials
import MaterialsView from "@/views/AdminDashboard/Materials/MaterialsView.vue";
// Admin add materials
import AddMaterial from "@/views/AdminDashboard/Materials/AddMaterial.vue";
// Admin users
import UsersView from "@/views/AdminDashboard/Users/UsersView.vue";
// Admin discounts
import DiscountsView from "@/views/AdminDashboard/Discounts/DiscountsView.vue";
// Admin add discounts
import AddDiscount from "@/views/AdminDashboard/Discounts/AddDiscount.vue";
// Admin News
import AdminNews from "@/views/AdminDashboard/News/NewsView.vue";
// Admin add News
import AddNews from "@/views/AdminDashboard/News/AddNews.vue";
// Admin products
import AdminProducts from "@/views/AdminDashboard/Products/AdminProducts.vue";
// Admin add products
import AddProduct from "@/views/AdminDashboard/Products/AddProduct.vue";
// Admin edit products
import EditProduct from "@/views/AdminDashboard/Products/EditProduct.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // Products
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/product-info",
    name: "ProductInfo",
    component: ProductInfo,
  },
  // News
  {
    path: "/projects",
    name: "News",
    component: NewsView,
  },
  {
    path: "/project-info",
    name: "NewsInfo",
    component: NewsInfo,
  },
  // Basket
  {
    path: "/basket",
    name: "BasketView",
    component: BasketView,
  },
  {
    path: "/checkout",
    name: "CheckoutView",
    component: CheckoutView,
  },
  // Favourite
  {
    path: "/favourite",
    name: "FavouriteView",
    component: FavouriteView,
  },
  // login
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  // register
  {
    path: "/register",
    name: "RegisterView",
    component: RegisterView,
  },
  // Portifilio
  {
    path: "/about-us",
    name: "PortfilioPage",
    component: PortfilioPage,
  },
  // Portifilio
  {
    path: "/message-vison",
    name: "MessagePage",
    component: MessagePage,
  },
  // Admin dashboard
  {
    path: "/admin-home",
    name: "AdminHome",
    component: AdminHome,
  },
  // Admin orders
  {
    path: "/admin-orders",
    name: "OrdersView",
    component: OrdersView,
  },
  // Admin sections
  {
    path: "/admin-sections",
    name: "SectionsView",
    component: SectionsView,
  },
  // Admin add sections
  {
    path: "/admin-add-section",
    name: "AddSection",
    component: AddSection,
  },
  // Admin edit sections
  {
    path: "/admin-edit-section",
    name: "EditSection",
    component: EditSection,
  },
  // Admin Categories
  {
    path: "/admin-categories",
    name: "CategoriesView",
    component: CategoriesView,
  },
  // Admin add category
  {
    path: "/admin-add-category",
    name: "AddCategory",
    component: AddCategory,
  },
  // Admin edit category
  {
    path: "/admin-edit-category",
    name: "EditCategory",
    component: EditCategory,
  },
  // Admin sub category
  {
    path: "/admin-sub-categories",
    name: "SubcategoriesView",
    component: SubcategoriesView,
  },
  // Admin add sub category
  {
    path: "/admin-add-sub-category",
    name: "AddSubcategory",
    component: AddSubcategory,
  },
  // Admin add sub category
  {
    path: "/admin-edit-sub-category",
    name: "EditSubcategory",
    component: EditSubcategory,
  },
  // Admin colors
  {
    path: "/admin-colors",
    name: "ColorsView",
    component: ColorsView,
  },
  // Admin add colors
  {
    path: "/admin-add-color",
    name: "AddColor",
    component: AddColor,
  },
  // Admin Materials
  {
    path: "/admin-materials",
    name: "MaterialsView",
    component: MaterialsView,
  },
  // Admin add Materials
  {
    path: "/admin-add-material",
    name: "AddMaterial",
    component: AddMaterial,
  },
  // Admin users
  {
    path: "/admin-users",
    name: "UsersView",
    component: UsersView,
  },
  // Admin discounts
  {
    path: "/admin-discounts",
    name: "DiscountsView",
    component: DiscountsView,
  },
  // Admin add discounts
  {
    path: "/admin-add-discount",
    name: "AddDiscount",
    component: AddDiscount,
  },
  // Admin News
  {
    path: "/admin-news",
    name: "AdminNews",
    component: AdminNews,
  },
  // Admin add News
  {
    path: "/admin-add-news",
    name: "AddNews",
    component: AddNews,
  },
  // Admin products
  {
    path: "/admin-products",
    name: "AdminProducts",
    component: AdminProducts,
  },
  // Admin add products
  {
    path: "/admin-add-product",
    name: "AddProduct",
    component: AddProduct,
  },
  // Admin edit products
  {
    path: "/admin-edit-product",
    name: "EditProduct",
    component: EditProduct,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
