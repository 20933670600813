<template>
  <head>
    <title>الطلبات</title>
  </head>
  <div class="orders-view">
    <SideBar>
      <div class="container bg-light h-100 overflow-scroll">
        <h1 class="mt-5 mb-3">الطلبات ({{ shown_data.length }})</h1>
        <div class="search-bar mb-5 w-100">
          <button
            type="button"
            class="mybtn"
            @click="
              type = 'pending ';
              filterData();
            "
          >
            قيد الانتظار
          </button>
          <button
            type="button"
            class="mybtn"
            @click="
              type = 'done';
              filterData();
            "
          >
            منتهي
          </button>
          <button
            type="button"
            class="mybtn"
            @click="
              type = 'reject';
              filterData();
            "
          >
            مرفوض
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الاسم</th>
                <th scope="col">رقم الهاتف</th>
                <th scope="col">الايميل</th>
                <th scope="col">رقم الهاتف</th>
                <th scope="col">حالة الطلب</th>
                <th scope="col">استعراض الطلب</th>
                <th scope="col" v-if="type == 'pending '">تم تنفيذ الطلب</th>
                <th scope="col" v-if="type == 'pending '">تم رفض الطلب</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shown_data" :key="index">
                <th scope="row">{{ item.id }}</th>
                <td>{{ item.fname + " " + item.lname }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  {{
                    item.status == "pending "
                      ? "قيد الإنتظار"
                      : item.status == "done"
                      ? "مكتمل"
                      : "مرفوض"
                  }}
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-dark"
                    @click="
                      order = item;
                      Zindex = 1000;
                      dis = 'flex';
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path d="M0 0h24v24H0z" />
                        <path
                          fill="currentColor"
                          d="M10.5 2a8.5 8.5 0 0 1 6.676 13.762l3.652 3.652a1 1 0 0 1-1.414 1.414l-3.652-3.652A8.5 8.5 0 1 1 10.5 2m0 2a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13m0 1a5.5 5.5 0 1 1 0 11a5.5 5.5 0 0 1 0-11"
                        />
                      </g>
                    </svg>
                  </button>
                </td>
                <td v-if="type == 'pending '">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="orderDone(item.id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 2048 2048"
                    >
                      <path
                        fill="currentColor"
                        d="m1902 196l121 120L683 1657L25 999l121-121l537 537z"
                      />
                    </svg>
                  </button>
                </td>
                <td v-if="type == 'pending '">
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="orderRefuse(item.id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        d="M31 9v16.731c0 .987 1.277 1.377 1.829.56l2.724-4.036a2.552 2.552 0 0 1 4.36 2.642l-6.938 12.816A12 12 0 0 1 22.422 44H21c-6.627 0-12-5.373-12-12V15a2 2 0 1 1 4 0v10.111h2V9a2 2 0 1 1 4 0v14h2V6a2 2 0 1 1 4 0v17h2V9a2 2 0 1 1 4 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div
        class="close"
        @click="
          Zindex = -1;
          dis = 'none';
        "
      >
        X
      </div>
      <div class="card">
        <div class="card-title">
          اسم العميل: {{ order.fname + " " + order.lname }}
        </div>
        <div class="card-title">رقم الهاتف: {{ order.phone }}</div>
        <div class="card-title">ايميل: {{ order.email }}</div>
        <div class="card-title">المدينة: {{ order.city_name }}</div>
        <div class="card-title">الشارع: {{ order.street_address }}</div>
        <div class="card-title">المنتجات:</div>
        <div class="table-responsive w-100">
          <table class="table table-striped table-hover w-100">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الاسم</th>
                <th scope="col">الرقم</th>
                <th scope="col">السعر</th>
                <th scope="col">الكمية</th>
              </tr>
            </thead>
            <tbody v-for="(item2, index2) in order.item" :key="index2">
              <tr>
                <th scope="row">{{ item2.it.id }}</th>
                <td>{{ item2.it.name }}</td>
                <td>{{ item2.it.number }}</td>
                <td>
                  {{
                    item2.it.discount_price != null
                      ? item2.it.discount_price
                      : item2.it.price
                  }}
                </td>
                <td>{{ item2.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-title">
          السعر الكلي مع الضريبة: {{ order.total_price }}
        </div>
        <div class="card-title">قيمة الضريبة: {{ order.tax }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "orders-view",
  data() {
    return {
      data: [],
      shown_data: [],
      type: "pending ",
      dis: "none",
      Zindex: -3,
      order: [],
      shown_items: [],
    };
  },
  components: {
    SideBar,
  },
  methods: {
    getData() {
      axios
        .get(store.state.server + "order-index")
        .then((response) => {
          this.data = response.data;
          console.log(this.data);
          this.filterData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterData() {
      this.shown_data = this.data.filter((item) =>
        item.status.includes(this.type)
      );
    },
    orderDone(id) {
      axios
        .get(store.state.server + "order-done/" + id)
        .then((response) => {
          if (response.status == 200) {
            alert("تم اكمال الطلب بنجاح");
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    orderRefuse(id) {
      axios
        .get(store.state.server + "order-reject/" + id)
        .then((response) => {
          if (response.status == 200) {
            alert("تم اكمال الرفض بنجاح");
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.mybtn {
  background-color: orange;
  color: whitesmoke;
  width: 150px;
  height: 50px;
  font-size: 20px;
  padding: 0 10px;
  border: none;
  border-radius: 10px;
  margin: 0 10px;
}

.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  align-items: center;
}

.card {
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.close {
  font-size: 24px;
  position: fixed;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
