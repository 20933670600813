import { createStore } from "vuex";

export default createStore({
  state: {
    section: 0,
    category: 0,
    sub_category: 0,
    discount: 0,
    basket: [],
    index: 0,
    balance: 0,
    tax: 0,
    total_balance: 0,
    server: "https://www.1hds.net/hds/public/api/",
    server_images: "https://1hds.net/hds/public/app/public/",
    token: "",
    role: "",
  },
  getters: {},
  mutations: {
    getAttr() {
      this.state.section = 0;
      this.state.category = 0;
      this.state.sub_category = 0;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("section")) {
        if (urlParams.get("section") != this.state.section) {
          this.state.section = urlParams.get("section");
        }
      } else if (urlParams.has("category")) {
        if (urlParams.get("category") != this.state.category) {
          this.state.category = urlParams.get("category");
        }
      } else if (urlParams.has("id")) {
        if (urlParams.get("id") != this.state.sub_category) {
          this.state.sub_category = urlParams.get("id");
        }
      } else if (urlParams.has("discount")) {
        this.state.discount = urlParams.get("discount");
      }
    },
    RemoveFromBasket(state, index) {
      var i = index;
      this.state.balance -=
        this.state.basket[index].price_dis == null
          ? this.state.basket[index].price * this.state.basket[index].quantity
          : this.state.basket[index].price_dis *
            this.state.basket[index].quantity;
      if (index == Object.keys(this.state.basket).length - 1) {
        this.state.basket.splice(index, 1);
      } else if (Object.keys(this.state.basket).length > 1) {
        for (i; i < Object.keys(this.state.basket).length - 1; i++) {
          this.state.basket[i] = this.state.basket[i + 1];
          index++;
        }
      }
      this.state.basket.splice(i, 1);
      this.state.index--;
      window.localStorage.setItem("basket", JSON.stringify(this.state.basket));
      window.localStorage.setItem("index", JSON.stringify(this.state.index));
      window.localStorage.setItem(
        "balance",
        JSON.stringify(this.state.balance)
      );
    },
  },
  actions: {},
  modules: {},
});
